import React from 'react';
import _ from 'lodash';
import seapig, { REQUIRED, REQUIREDS } from 'seapig';

import { PageTitle } from '../components/title';
import Layout from '../components/layout';
import { GridRow } from '../components/widgets/grid';

import HelpDeskTechnician from './svg/icons/noun_help_desk_technician_1200622_000000.svg';
import Envelope from './svg/icons/noun_envelope_185638_000000.svg';

const Help = ({url, children, action}) => {

  const {
    iconChildren,
    channelChildren,
  } = seapig(children, {
    icon: REQUIRED,
    channel: REQUIREDS,
  });

  return (
    <div className="ba br2 b--gray col-xs-8 col-lg-3 gradient-yellow pa3">

      <div className="flex justify-around">

        <div className="flex flex-column justify-around">

          <div className="flex justify-around">
            <a href={url}>
              {iconChildren}
            </a>
          </div>

          <a className="near-black link dim" href={url}>
            <h1 className="tc">{action}</h1>
          </a>


          <div className="flex flex-column items-end">
            <a className="mid-gray link dim" href={url}>
              {channelChildren}
            </a>
          </div>

        </div>

      </div>
    </div>
  );
}

const Support = () =>
  <div className="pb5">
    <PageTitle title="Support Options"/>

    <GridRow>

      <div className="row center-lg">

        <div className="col-xs-2 col-lg-1"></div>

        <Help url='tel:+441865950200' action='Call us'>
          <HelpDeskTechnician icon className="w3 h3" />
          <div channel className="tc f3">01865 950 200</div>
        </Help>

        <div className="col-xs-2 col-lg-1"></div>
        <div className="col-xs-12 h2"></div>
        <div className="col-xs-2"></div>

        <Help url='mailto:support@switchsmith.com' action='Mail us'>
          <Envelope icon className="w3 h3" />
          <div channel className="tc">support@switchsmith.com</div>
        </Help>

        <div className="col-xs-2"></div>

      </div>

    </GridRow>
  </div>

const SupportPage = () =>
  <Layout>
    <Support />
  </Layout>

export default SupportPage;